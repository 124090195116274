import React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import {Chip, Modal, ModalClose, ModalDialog, Snackbar, Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";

// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";
import UserContext from "../../context/UserContext";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoginIcon from "@mui/icons-material/Login";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {CustomSwitch} from "../Shared/FormFields";
import PersonalData from "../Shared/PersonalData";
import Rules from "../Shared/Rules";


class WebinarAttendeeCreate extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formErrors: {},
            formFields: null,
            success: false,
            semiSuccess: false,
            snackbar: false,
            attended: this.props.webinar.attended,
            login: false,
            signUp: true,
            reload: false,
            loginFormErrors: {},
            loginFormFields: [
                {
                    name: "username",
                    label: "Электронная почта",
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: null,
                    required: true,
                    default: null,
                    type: "input"
                },
                {
                    name: "password",
                    label: "Пароль",
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: null,
                    required: true,
                    default: null,
                    type: "password"
                },
                {
                    type: "submit",
                    label: "Войти и зарегистрироваться",
                    color: 'success',
                }
            ]
        };
    }

    async retrieveFields(){
        const url = `user/create_form/`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        let formFields = response.data;
        let submit = formFields.pop()
        formFields.push(
            {
                type: 'component',
                name: 'personal_data',
                component: <Stack
                    direction={'row'}
                    useFlexGap
                    spacing={1}
                    sx={{
                        flexWrap: 'wrap',
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <CustomSwitch
                        required={true}
                        name={'personal_data'}
                        value={false}
                        label={''}
                    />
                    <Typography>
                        Я безоговорочно присоединяюсь к
                    </Typography>
                    <PersonalData />
                    <Typography>
                        и
                    </Typography>
                    <Rules />
                    <Typography>
                        .
                    </Typography>
                </Stack>
            },
            {
                type: 'component',
                name: 'mailing',
                component: <Stack
                    direction={'row'}
                    useFlexGap
                    spacing={1}
                    sx={{
                        flexWrap: 'wrap',
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <CustomSwitch
                        required={true}
                        name={'mailing'}
                        value={true}
                        label={''}
                    />
                    <Typography>
                        Я согласен получать информационные материалы на свой электронный адрес
                    </Typography>
                </Stack>
            }
        )
        formFields.push(submit);
        return(formFields)
    }

    async setModalOpenTrue(){
        let formFields = await this.retrieveFields();
        await this.setState({
            modalOpen: true,
            formFields: formFields,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        let url = `user/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 400 && 'email' in response.data){
            // TODO: add some logics for password refresh and no-login attendee
            this.setState({
                semiSuccess: true,
                signUp: false,
            });
        }
        if (response.status === 400) {
            this.setState({
                formErrors: response.data
            })
        }
        if (response.status === 201) {
            localStorage.setItem(
                "authToken",
                response.data.token
            )
            await this.attend();
            this.setState({
                success: true,
                reload: true,
                signUp: false,
            });
        }
    }

    async processLoginForm(form){
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        const url = `token/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 400) {
            this.setState({
                loginFormErrors: response.data
            })
        }
        if (response.status === 200){
            localStorage.setItem(
                "authToken",
                response.data.token
            );
            await this.attend();
            this.setState({
                login: false,
                success: true,
                reload: true,
            });
        }
    }

    async attend(){
        const api = new BaseApi();
        const url = `webinar/${this.props.webinar.id}/attend/`;
        let response = await api.get(
            url,
            {}
        );
        if (response.status === 201){
            this.setState({
                attended: true,
                snackbar: true,
            });
        }
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        if (this.state.reload){
            // maybe navigate to detail?
            window.location.reload();
        }
    }

    render() {
        if (this.context.userObject){
            if (this.state.attended){
                return (
                    <>
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            startDecorator={<CheckCircleIcon/>}
                            open={this.state.snackbar}
                            onClose={
                                () => {
                                    this.setState({
                                        snackbar: false,
                                    });
                                }
                            }
                            endDecorator={
                                <Button
                                    onClick={
                                        () => {
                                        this.setState({
                                            snackbar: false,
                                        });
                                    }}
                                    size="sm"
                                    variant="soft"
                                    color="success"
                                >
                                    Закрыть
                                </Button>
                            }
                            color={'success'}
                        >
                            Вы успешно зарегистрированы на вебинар "{this.props.webinar.title}"!
                        </Snackbar>
                        <Chip
                            variant="outlined"
                            color="success"
                            size="lg"
                            startDecorator={<CheckCircleIcon/>}
                        >
                             Вы зарегистрированы!
                        </Chip>
                    </>
                )
            }
            else{
                if (!this.props.webinar.ended){
                    return (
                        <Button
                            variant="solid"
                            color="success"
                            size="sm"
                            onClick={this.attend.bind(this)}
                            startDecorator={<AddCircleIcon/>}
                        >
                            Зарегистрироваться
                        </Button>
                    )
                }
            }
        }
        if (!this.props.webinar.ended){
            return (
                <Box>
                    <Button
                        variant="solid"
                        color="success"
                        size="sm"
                        onClick={this.setModalOpenTrue.bind(this)}
                        startDecorator={<AddCircleIcon/>}
                    >
                         Записаться
                    </Button>
                    <Modal
                        open={this.state.modalOpen}
                    >
                        <ModalDialog
                            layout={'center'}
                            size="lg"
                        >
                            <ModalClose
                                onClick={this.setModalOpenFalse.bind(this)}
                            />
                            <Box
                                sx={{
                                    overflowY: 'scroll',
                                    maxWidth: 'calc(80vw)',
                                    pr: 2
                                }}
                            >
                                {
                                    this.state.login?
                                    <Stack>
                                        <Typography
                                            level={'h3'}
                                            sx={{
                                                mb: 1
                                            }}
                                        >
                                            Вход в систему
                                        </Typography>
                                        <CommonForm
                                            errors={this.state.loginFormErrors}
                                            fields={this.state.loginFormFields}
                                            processForm={this.processLoginForm.bind(this)}
                                        />
                                        <Button
                                            size={'sm'}
                                            onClick={
                                                () => {this.setState({
                                                    signUp: true,
                                                    login: false,
                                                    loginFormErrors: {},
                                                })}
                                            }
                                            startDecorator={<ArrowBackIcon />}
                                            color={'default'}
                                        >
                                            Вернуться к регистрации
                                        </Button>
                                    </Stack>:
                                    null
                                }
                                {
                                    this.state.success?
                                        <Stack>
                                            <Typography
                                                level={'h3'}
                                            >
                                                Вы успешно зарегистрированы на вебинар &#xab;{this.props.webinar.title}&#xbb;!
                                            </Typography>
                                            <Typography>
                                                В ближайшее время Вы получите электронное письмо с подтверждением.
                                            </Typography>
                                            <Typography>
                                                За 1 день до мероприятия на указанную почту будет выслана ссылка для подключения к трансляции.
                                            </Typography>
                                            <Button
                                                color="success"
                                                sx={{mt: 2}}
                                                onClick={this.setModalOpenFalse.bind(this)}
                                            >
                                                Закрыть вкладку
                                            </Button>
                                        </Stack>:
                                        null
                                }
                                {this.state.semiSuccess?
                                    <Stack>
                                        <Typography
                                            level={'h3'}
                                        >
                                            Вы успешно зарегистрированы на
                                            вебинар &#xab;{this.props.webinar.title}&#xbb;!
                                        </Typography>
                                        <Typography>
                                            Чтобы управлять своей регистрацией воспользуйтесь личным кабинетом.
                                            Обновленные данные для входа высланы на указанную электронную почту.
                                        </Typography>
                                        <Button
                                            color="success"
                                            sx={{
                                                mt: 2
                                            }}
                                            onClick={this.setModalOpenFalse.bind(this)}
                                        >
                                            Закрыть вкладку
                                        </Button>
                                    </Stack> :
                                    null
                                }
                                {this.state.signUp?
                                    <Stack>
                                        <Typography
                                            level={'h3'}
                                        >
                                            Для записи на вебинар требуется пройти регистрацию
                                        </Typography>
                                        <Stack
                                            direction={'row'}
                                            spacing={1}
                                            sx={{
                                                pt: 2,
                                                pb: 4,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography
                                            >
                                                Если знаете данные для входа:
                                            </Typography>
                                            <Button
                                                size={'sm'}
                                                onClick={() => {
                                                    this.setState({
                                                        login: true,
                                                        signUp: false,
                                                    })
                                                }}
                                                startDecorator={<LoginIcon />}
                                                color={'success'}
                                            >
                                                Войти в систему
                                            </Button>
                                        </Stack>
                                        <CommonForm
                                            errors={this.state.formErrors}
                                            fields={this.state.formFields}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </Stack>:
                                    null
                                }
                            </Box>
                        </ModalDialog>
                    </Modal>
                </Box>
            )
        }
        return(
            <Chip
                color="warning"
                sx={{
                    height: '0px'
                }}
            >
                Регистрация подошла к концу
            </Chip>
        )
    }
}

export default WebinarAttendeeCreate