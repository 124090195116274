import React from 'react';
import {Avatar, Button, Chip, Modal, ModalClose, ModalDialog, Stack, Typography} from "@mui/joy";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";

// components
import BaseApi from "../../utils/BaseApi";
import UserContext from "../../context/UserContext";
import CommonForm from "../Shared/CommonForm";
import PersonalData from "../Shared/PersonalData";
import Rules from "../Shared/Rules";
import {CustomSwitch} from "../Shared/FormFields";


// icons
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LoginIcon from '@mui/icons-material/Login';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';



class UserModal extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            login: true,
            reset: false,
            resetSuccess: false,
            signUp: false,
            signUpFormErrors: {},
            signUpFormFields: null,
            resetFormErrors: {},
            resetFormFields: [
                {
                    name: "email",
                    label: "Электронная почта",
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: null,
                    required: true,
                    default: null,
                    type: "input"
                },
                {
                    type: "submit",
                    label: "Сбросить",
                    color: 'success',
                }
            ],
            loginFormErrors: {},
            loginFormFields: [
                {
                    name: "username",
                    label: "Электронная почта",
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: null,
                    required: true,
                    default: null,
                    type: "input"
                },
                {
                    name: "password",
                    label: "Пароль",
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: null,
                    required: true,
                    default: null,
                    type: "password"
                },
                {
                    type: "submit",
                    label: "Войти",
                    color: 'success',
                }
            ]
        };
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
            signUp: false,
            login: true,
            reset: false,
        })
    }

    async setSignUp() {
        let formFields = await this.retrieveSignUpForm();
        await this.setState({
            signUp: true,
            login: false,
            signUpFormFields: formFields,
        })
    }

    async retrieveSignUpForm() {
        const url = `user/create_form/`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        let formFields = response.data;
        let submit = formFields.pop()
        formFields.push(
            {
                type: 'component',
                name: 'personal_data',
                component: <Stack
                    direction={'row'}
                    useFlexGap
                    spacing={1}
                    sx={{
                        flexWrap: 'wrap',
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <CustomSwitch
                        required={true}
                        name={'personal_data'}
                        value={false}
                        label={''}
                    />
                    <Typography>
                        Я безоговорочно присоединяюсь к
                    </Typography>
                    <PersonalData />
                    <Typography>
                        и
                    </Typography>
                    <Rules />
                    <Typography>
                        .
                    </Typography>
                </Stack>
            },
            {
                type: 'component',
                name: 'mailing',
                component: <Stack
                    direction={'row'}
                    useFlexGap
                    spacing={1}
                    sx={{
                        flexWrap: 'wrap',
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <CustomSwitch
                        required={true}
                        name={'mailing'}
                        value={true}
                        label={''}
                    />
                    <Typography>
                        Я согласен получать информационные материалы на свой электронный адрес
                    </Typography>
                </Stack>
            }
        )
        formFields.push(submit);
        return(response.data)
    }

    async processResetForm(form){
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        let url = `user/reset/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 200){
            this.setState({
                resetSuccess: true,
            });
        }
        if (response.status === 400){
            this.setState({
                resetFormErrors: response.data
            })
        }
    }

    async processSignUpForm(form){
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        let url = `user/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201){
            localStorage.setItem(
                "authToken",
                response.data.token
            )
            window.location.reload();
        }
        if (response.status === 400){
            this.setState({
                signUpFormErrors: response.data
            })
        }
    }

    async processLoginForm(form){
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        const url = `token/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 200){
            localStorage.setItem(
                "authToken",
                response.data.token
            )
            window.location.reload();
        }
        if (response.status === 400){
            this.setState({
                loginFormErrors: response.data
            })
        }
    }

    async logout() {
        localStorage.removeItem(
            "authToken",
        );
        window.location.replace(window.location.origin);
        // window.location.reload();
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        if (!this.context.userObject){
            return (
                <>
                    <Stack>
                        <Button
                            onClick={this.setModalOpenTrue.bind(this)}
                            startDecorator={<LoginIcon />}
                            color={'default'}
                            sx={{

                            }}
                        >
                            Войти
                        </Button>
                    </Stack>
                    <Modal
                        open={this.state.modalOpen}
                    >
                        <ModalDialog
                            layout={'center'}
                            size="lg"
                        >
                            <ModalClose
                                onClick={this.setModalOpenFalse.bind(this)}
                            />
                            <Box
                                sx={{
                                    overflowY: 'scroll',
                                    maxWidth: 'calc(80vw)',
                                    pr: 2
                                }}
                            >
                                {
                                    this.state.login?
                                        <Stack>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 1
                                                }}
                                            >
                                                Вход в систему
                                            </Typography>
                                            <CommonForm
                                                fields={this.state.loginFormFields}
                                                errors={this.state.loginFormErrors}
                                                processForm={this.processLoginForm.bind(this)}
                                            />
                                            <Stack
                                                direction={'row'}
                                                sx={{
                                                    pt: 2,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        mr: 2
                                                    }}
                                                >
                                                    Еще нет аккаунта?
                                                </Typography>
                                                <Button
                                                    size={'sm'}
                                                    onClick={this.setSignUp.bind(this)}
                                                    startDecorator={<PersonAddAlt1Icon/>}
                                                >
                                                    Зарегистрироваться
                                                </Button>
                                            </Stack>
                                            <Stack
                                                direction={'row'}
                                                sx={{
                                                    pt: 2,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        mr: 2
                                                    }}
                                                >
                                                    Забыли пароль?
                                                </Typography>
                                                <Button
                                                    size={'sm'}
                                                    onClick={
                                                        () => {
                                                            this.setState({
                                                                reset: true,
                                                                signUp: false,
                                                                login: false
                                                            })
                                                        }
                                                    }
                                                    startDecorator={<RefreshIcon/>}
                                                    color={'default'}
                                                >
                                                    Сбросить
                                                </Button>
                                            </Stack>
                                        </Stack>:
                                        null
                                }
                                {
                                    this.state.signUp?
                                        <Stack>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 1
                                                }}
                                            >
                                                Регистрация
                                            </Typography>
                                            <CommonForm
                                                fields={this.state.signUpFormFields}
                                                errors={this.state.signUpFormErrors}
                                                processForm={this.processSignUpForm.bind(this)}
                                            />
                                            <Stack
                                                direction={'row'}
                                                sx={{
                                                    pt: 2,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Button
                                                    size={'sm'}
                                                    onClick={
                                                        () => {this.setState({
                                                            signUp: false,
                                                            login: true,
                                                            reset: false,
                                                        })}
                                                    }
                                                    startDecorator={<ArrowBackIcon />}
                                                    color={'default'}
                                                >
                                                    Вернуться к входу
                                                </Button>
                                            </Stack>
                                        </Stack>:
                                        null
                                }
                                {
                                    this.state.reset?
                                        <Stack>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 1
                                                }}
                                            >
                                                Сброс пароля
                                            </Typography>
                                            <CommonForm
                                                fields={this.state.resetFormFields}
                                                errors={this.state.resetFormErrors}
                                                processForm={this.processResetForm.bind(this)}
                                            />
                                            {this.state.resetSuccess?
                                                <Chip
                                                    color={'success'}
                                                >
                                                    Ваш пароль сброшен успешно! Вы можете вернуться к форме входа по кнопке ниже.
                                                </Chip>:
                                                null
                                            }
                                            <Stack
                                                direction={'row'}
                                                sx={{
                                                    pt: 2,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Button
                                                    size={'sm'}
                                                    onClick={
                                                        () => {this.setState({
                                                            signUp: false,
                                                            login: true,
                                                            reset: false,
                                                        })}
                                                    }
                                                    startDecorator={<ArrowBackIcon />}
                                                    color={'default'}
                                                >
                                                    Вернуться к входу
                                                </Button>
                                            </Stack>
                                        </Stack>:
                                        null
                                }
                            </Box>
                        </ModalDialog>
                    </Modal>
                </>
            )
        }
        return(
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar
                    variant="outlined"
                    size="sm"
                >
                    {Array.from(this.context.userObject.first_name)[0]}{Array.from(this.context.userObject.last_name)[0]}
                </Avatar>
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography
                        level="title-sm"
                    >
                        {this.context.userObject.first_name} {this.context.userObject.last_name}
                    </Typography>
                    <Typography
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}
                        level="body-xs"
                    >
                        {this.context.userObject.email}
                    </Typography>
                </Box>
                <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={this.logout.bind(this)}
                >
                    <LogoutRoundedIcon/>
                </IconButton>
            </Box>
        )
    }
}

export default UserModal