import {createContext, useState, useEffect} from "react";
import BaseApi from "../utils/BaseApi";


const UserContext = createContext({});
export default UserContext;


export const UserProvider = ({children}) => {
    const [userObject, setUserObject] = useState(null);
    const [loading,setLoading] = useState(true);

    const getData = async ()=> {
        const api = new BaseApi();
        let response = await api.get(
            'user/me',
            {}
        );
        if (response.status === 200) {
            setUserObject(response.data)
        }
        else {
            setUserObject(null);
        }
    }

    useEffect(()=> {
        getData().then(
            setLoading(false),
        )
    },[])

    let contextData = {
        userObject: userObject,
    };

    return (
        <UserContext.Provider value={contextData}>
            {loading?null:children}
        </UserContext.Provider>
    );
};