import React from 'react';


import {
    AspectRatio,
    Card,
    CardContent,
    CardOverflow,
    Chip,
    CircularProgress,
    Grid,
    Stack,
    Typography
} from "@mui/joy";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import withNavigate from "../../utils/withNavigate";
import PostCard from "./PostCard";

// icons
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";


class PostDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
            news: null,
            projects: null,
            blog: null,
        };
    }

    async componentDidMount() {
        await this.retrievePost();
    }

    async retrievePost(){
        const url = `post/${this.props.params.postId}/`;
        const params = {

        };
        const api = new BaseApi(
            'https://multiphysics.ru',
            '/api/'
        );
        let response = await api.get(
            url,
            params
        );
        let post = response.data;
        post.text = post.text.replaceAll('../../../..', 'https://multiphysics.ru');
        this.setState(
            {
                post: post
            }
        );
        await this.retrievePosts(post);
    }

    async retrievePosts(post){
        const url = 'post/';
        let response;
        let params;
        let news;
        let projects;
        let blog;
        params = {
            published: true,
            page_size: 4,
        };
        const api = new BaseApi(
            'https://multiphysics.ru',
            '/api/',
            null,
            'repeat',
        );
        // news
        params.category = 5;
        params.tag = post.tag.map((each) => (each.id));
        response = await api.get(
            url,
            params
        );
        news = response.data.results
        // projects
        params.category = 6;
        response = await api.get(
            url,
            params
        );
        projects = response.data.results
        // blog
        params.category = 7;
        response = await api.get(
            url,
            params
        );
        blog = response.data.results;
        this.setState(
            {
                news: news,
                projects: projects,
                blog: blog,
            }
        );
    }

    render() {
        if (!this.state.post || !this.state.news || !this.state.projects || !this.state.blog){
            return(
                <CircularProgress/>
            )
        }
        return(
            <Stack
                spacing={2}
            >
                <Card>
                    <CardOverflow>
                        <AspectRatio
                            ratio="5/1"
                            sx={{ minWidth: 200 }}
                        >
                            <img
                                src={'https://multiphysics.ru/media/'+this.state.post.image}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </CardOverflow>
                    <CardContent>
                        <Typography
                            level="h3"
                            sx={{
                                pb: 2,
                                pt: 2,
                            }}
                        >
                            {this.state.post.title}
                        </Typography>
                        <Box
                            sx={{
                                pb: 2,
                            }}
                        >
                            <Stack
                                spacing={1}
                                direction={'row'}
                                useFlexGap
                                sx={{ flexWrap: 'wrap' }}
                            >
                                {this.state.post.tag.map((each) =>(
                                    <Chip>{each.title}</Chip>
                                ))}
                            </Stack>
                        </Box>
                        <Typography>
                            {this.state.post.short}
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: this.state.post.text}} />
                    </CardContent>
                </Card>
                <Typography
                    level={'h2'}
                >
                    Связанные новости
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.news.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/news/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
                <Typography
                    level={'h2'}
                >
                    Связанные проекты
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.projects.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/projects/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
                <Typography
                    level={'h2'}
                >
                    Связанные публикации в блоге
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.blog.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/blog/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
            </Stack>
        )
    }
}

export default withNavigate(withRouter(PostDetail));
