import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import router from './router'
import reportWebVitals from "./reportWebVitals";
import axios from "axios";


document.addEventListener('DOMContentLoaded', function(){
    let now = new Date();
    if (localStorage.getItem('visit-id')){
        if (localStorage.getItem('visit-last-dt')) {
            if (now.getTime() - localStorage.getItem('visit-last-dt') < 20000) {
                return
            }
        }
    }
    axios.post(
        `${process.env.REACT_APP_API_HOST}/api/visit/`,
        {
            headers: {},
            data: {
                refferer: document.referrer,
            },
        }
    ).then(function (response) {
        localStorage.setItem(
            'visit-id',
            response.data.id
        );
        localStorage.setItem(
            'visit-last-dt',
            now.getTime()
        );
    })
    .catch(function (error) {
        console.log(error);
    });
});

ReactDOM.createRoot(
    document.getElementById("root")
).render(
  // <React.StrictMode>
      <RouterProvider router={router}/>
  // </React.StrictMode>
);

reportWebVitals();
